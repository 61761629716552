























































































































































.contentful-text-and-image {

  .content-container {
    column-gap: 1.2rem;
    display: grid;
    grid-template-columns: 54% fit-content(46%);
    grid-template-rows: auto;
    padding-bottom: 3rem;
    max-width: 1300px;
    margin: auto;

    .youtube-video {
      aspect-ratio: 16/9;
      height: auto;
    }

    @media(max-width: 767px){
      .img-container {
        position: relative;
        overflow: hidden;
      }
    }

    &.on-frontpage {
      @media(min-width: 1300px) {
        max-width: 1300px;
      }
    }

    .arrows {
      pointer-events: none;
      position: absolute;
      &.arrow-1 {
        width: 240px;
        margin-left: -575px;
        margin-top: 160px;
      }
      &.arrow-2 {
        width: 200px;
        margin-top: 8px;
        margin-left: -145px;
      }
      &.arrow-3 {
        width: 210px;
        margin-top: 130px;
        margin-left: -550px;
      }
      &.arrow-4 {
        width: 250px;
        margin-top: 0;
        margin-left: -245px
      }
    }

    .text-container {
      // padding-right: 0;
      // padding-left: 1rem;

      grid-column: 1;
      grid-row: 1;

      // font-size: .8rem;
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;

      ul {
        list-style: none;
        padding: 0 0 0 1rem;

        li:before {
          font-weight: bold;
          content: '✓';
          text-indent: 0;
          float: left;
          padding: 0 .5rem 0 0;
        }

        li p {
          padding: 0;
          margin: 0;
        }
      }

      h2 {
        font-weight: 700;
        font-size: 1.8rem;
      }
    }
  }

  &.flip {
    @media(min-width: 767px) {
      .content-container {
        grid-template-columns: fit-content(46%) 54%;

        .text-container {
          grid-column: 2;
          // padding-left: 0;
          // padding-right: 1rem;
        }
        img {
          grid-column: 1;
        }
      }
    }
  }

  img {
    grid-column: 2;
    grid-row: 1;
    max-width: 600px;
    width: 100%;
  }

  @media(max-width: 767px) {
    .content-container {
      grid-template-columns: auto;
      grid-template-rows: 1fr auto;
      padding-bottom: 2rem;

      .text-container {
        grid-column: 1;
        grid-row: 1;
        // padding: 0 1rem 1rem 1rem;

        h3 {
          margin-bottom: 1.5rem;
        }
      }

      img {
        grid-column: 1;
        grid-row: 2;
        width: 100%;
        max-width: 100%;
      }
    }

    &.flip-mobile {
      grid-template-rows: auto 1fr;
      .text-container {
        grid-row: 2;
      }
      img {
        grid-row: 1;
      }
    }
  }
}
