


































































































































































































































































































































































































































































































































































































































































































.dc-editor {

  // .custom-quote-alert {

  // }

  .custom-quote-btn {
    // font-size: 15px;
    font-size: 0.75rem;
  }

  .material-info {
    height: 80px;
  }

  // .material-category-family {
  //   width: 100%;
  // }

  .fallback-image {
    margin-top: 10px;
    max-width: 100%;
    max-height: 500px
  }
  .pricing-headers-container {
    width: 100%;
    span {
      flex: 3;
    }
  }
  .pricing-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .symbol-container {
      flex: 1;
    }
  }

  .pricebutton-editor {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    align-content: center;
    align-items: center;
    text-align: right;
  }

  .pricebutton-editor.in-cart {
    grid-template-columns: 30px 1fr;

    .btn {
      width: auto;
    }
  }

  .price-total-container {
    flex: 3;
    text-align: right;
  }

  .price-total-amount {
    font-size: 1.5rem;
    font-weight: 400;

    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size: 1.3rem;
    }
  }
  .price-total-amount-vat-msg {
    font-size: 0.7rem;
    // font-size: 14px;
  }

  .fallback-container{
    margin: 1rem;
  }

  .copy-button .btn {
    margin-top: 1rem;
  }

  .ordered-before-container {
    margin-bottom: 1rem;
  }

  .download .btn {
    margin-right: 10px;
   }

  .material-link:link,
  .material-link:active,
  .material-link:visited {
    color: #000;
    text-decoration: underline;
  }
  .material-link:hover {
    text-decoration: none;
  }

  .editor-add-to-cart .btn {
    width: 100%;
  }
}
