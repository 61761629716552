







































































































































































































































































































































.drawing-svg-viewer {
  display: grid;

  .btn-toolbar-layers {
    grid-row: 3;
    grid-column: 1;
    font-size: 0.8em;
    .btn-outline-secondary {
      font-size: 1em;
      border: 1px solid rgba(0,0,0,.2);
    }
    .btn:disabled,
    .btn[disabled] {
      .material-icons {
        opacity: .6;
      }
    }

  }

  .btn-toolbar-controls {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    justify-content: flex-end;

    .btn {
      min-width: inherit;
    }

    .btn-toolbar-controls-first-set,
    .btn-toolbar-controls-second-set {
      display: flex;
      grid-row: 1fr;
      justify-content: flex-end;
    }

    .btn-toolbar-controls-first-set {
      grid-column: 1;
    }
    .btn-toolbar-controls-second-set {
      grid-column: 2;
    }
  }

  @media (max-width: 530px) {
    .btn-toolbar-controls {
      margin-top: 1rem;
    }
  }

  @media (max-width: 400px) {
    .btn-toolbar-controls {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;

      .btn-toolbar-controls-first-set,
      .btn-toolbar-controls-second-set {
        grid-column: 1;
        justify-content: flex-start;
      }
      .btn-toolbar-controls-first-set {
        grid-row: 1;
      }
      .btn-toolbar-controls-second-set {
        grid-row: 2;
      }
    }
  }

  .svg-canvas {
    grid-row: 2;
    grid-column: 1;
  }

  .preview .bg-error {
    background-color: red;
    height: 100%;
  }

  .btn.btn-outline {
    border: 10px solid red;
    margin: 3px;
    font-weight: normal;
    background-image: none;
    background-color: #ffffff;
    &.active {
      border-width: 2px;
      margin: 2px;
      background-image: none;
    }
  }

  .btn.btn-all .material-icons {
    color: #000;
  }

  .btn.btn-cutting .material-icons {
    color: #00f;
  }

  .btn.btn-ignored .material-icons {
    color: #aaaaaa;
  }

  .btn.btn-help .material-icons {
    color: #000000;
  }

  .btn.btn-area-engrave .material-icons {
    color: magenta;
  }

  .btn.btn-area-engrave-original .material-icons {
    color: magenta;
  }

  .btn.btn-line-engrave .material-icons {
    color: red;
  }

  .btn.btn-outline:hover, .btn.btn-outline:active, .btn.btn-outline:focus {
    background-color: #ffffff;
    background-image: none;
    font-weight: normal;
  }

  .svg-loading-widget {
    position: absolute;
    margin-top: 30px;
    z-index: 1;
  }

  .drawing-size {
    font-size: 1rem;
    position: absolute;
  }
}
