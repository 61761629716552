





















































.contentful-header-with-three-columns {
  max-width: 1269px;
  margin: auto;
  padding: 3rem 0 0 0;

  h2 {
    font-size: 1.8rem;
    // line-height: 54px;
    font-weight: 700;
    // text-align: center;
  }
  .main-text p{
    font-size: 18px;
    // text-align: center;
    font-weight: 300;
    line-height: 30px;
  }

  .columns-container {
    display: flex;
    justify-content: space-around;
    @media(max-width:992px) {
      flex-wrap: wrap;
      justify-items: center;
    }

    .column {
      max-width: 450px;
      padding: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        max-width: 300px;
      }

      h3 {
        font-size: 24px;
        line-break: 36px;
        font-weight: 300;
      }

      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
}
