










































































































































































































































footer {
  .fluid-container {
    max-width: 1340px;
  }

  a:link, a:visited, a:active {
    color: #363636;
    text-decoration: none;
  }

  h6 {
    font-size: 0.8rem;
  }

  .payment-icon {
    width: 35px;
    margin: 0;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}
