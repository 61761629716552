

















































































#signin {
  padding: 0;
  padding-left: 0 !important;
}
.signin-modal {
  header, footer {
    display: none;
  }
  &.limit-width {
    .modal-dialog {
      max-width: 50rem;
    }
  }
  .modal {
    padding-left: 0 !important;
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.login-panel {
  display: flex;
}
