@import 'color';

$media-m: 764px !default;
// $media-l: 1200px !default;

.error-center {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.error-center-h1 {
  font-size: 1.5rem;
}

.error-center-h2 {
  font-size: 1rem;
}

.error-text-extra {
  display: inline-block;
  padding: 1rem;
}

@media all and (min-width: $media-m) {
  .logo-animated {
    width: 40rem;
  }

  .error-center-h1 {
    font-size: 3rem;
  }

  .error-center-h2 {
    font-size: 2rem;
  }
}

.logo-animated {
  display: inline-block;
  max-width: 90%;
  width: 40rem;

  @keyframes laser {
    from {
      stroke-dashoffset: 1000;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fadeBgIn {
    to {
      fill: rgba(243, 243, 243, 1);
    }
  }

  svg {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .svg-logo-bg {
    animation-delay: 5s;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
    animation-name: fadeBgIn;
    fill: rgba(243, 243, 243, 0);
    stroke: $color-primary;
    stroke-width: 0.1px;
  }

  .svg-logo-laser {
    animation-duration: 20s;
    animation-fill-mode: forwards;
    animation-name: laser;
    fill: none;
    stroke: $color-secondary;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke-width: 1px;
  }
}
