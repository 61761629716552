




















































.trustpilot-container {
  margin: auto;
  width: 100%;

  .widget-menu {
    iframe {
      margin-top: 0.5rem !important
    }
  }
}
