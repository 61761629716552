
































.dummy {
  color: red;
  // dummy class to fix a problem where the first class is ignored after loading daemonite, or something like that...
}

.loading-widget-full-width {
  display: flex;
  align-content: center;
  justify-content: center;
  min-height: 75vh;
  width: 100%;
}
