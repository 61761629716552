
































































.custom-order-item .card-body {
  display: flex;
  justify-content: space-between;

  .field-element {
    display: inline-block;
  }

  .custom-order-item-qty {
    width: 100px;
  }

  .submit-btn-field {
    align-self: end;
  }
}
