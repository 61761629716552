





































































































































































.dc-editor-feedback {

  .input-row-full {
    padding: 0;

    input {
      font-size: 0.7rem;
    }
  }

  .custom-select option {
      font-size: 15px;
      font-weight: 300;
  }

  .drawing-size {
    font-size: 1.0rem;
  }
}
