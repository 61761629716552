














































































































































































.cart-review-table {
  width: 100%;

  .total-column {
    min-width: 75px;
  }
}
