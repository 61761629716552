


























































.contentful-blogs {

  padding: 3rem 0 1rem 0;

  h2 {
    font-size: 1.8rem;
    // font-size: 42px;
    // font-weight: 300;
    font-weight: 700;
    line-height: 54px;
    text-align: center;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    max-width: 1300px;
    margin: auto;
    align-items: flex-start;

    .blog-item {
      flex: 1 1 50%;
      display: flex;
      flex-direction: row;

      @media(max-width: 767px){
        flex-direction: column;
        align-items: center;
      }

      h5 {
        font-size: 24px;
        line-height: 24px;
        font-weight: 300;
      }
      .category {
        font-size: 14px;
        background-color: #FFDD00;
        font-weight: 400;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        max-height: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0;
        margin: 0;
      }
      .img-container,
      .text-container {
        flex: 1 1 40%;
      }
      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
      }

      .category,
      .read-time {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
