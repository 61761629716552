






















































































































































.forgot-password-save-new {
  .invalid-feedback {
    background-color: #fff;
    font-weight: bold;
    padding: 4px;
  }
}
