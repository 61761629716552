






























































































































































































.input-icon-front:hover {
  cursor: default;
}
.input-row-label {
  width: 100%;
  position: relative;
  .queue {
    position: absolute;
    right: 0;
    top: -5px;
  }
}
