




























































































































































































































































































































.drawing-configuration-list {
  .dc-list {
    margin: 0 1rem 1rem;
  }

  .text-larger {
    font-size: 0.9rem;
  }

  .dc-list-filter-container {
    display: grid;
    background-color: #fff;
    grid-template-columns: 65px 1fr 1fr;
    padding: 10px 10px 10px 0;
  }

  // .dc-list-group-actions {
  //   display: flex;
  // }

  .dc-list-filter-select-all {
    grid-column: 1/2;
    grid-row: 1/2;
    display: block;
    padding-top: 15px;
  }

  .dc-list-filter-container .dc-list-filter-search {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .dc-list-group-actions {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
  }

  .dc-list-filter-buttons {
    grid-column: 2/4;
    grid-row: 2/3;
  }

  .chip-checked.bg-snijlab {
    background-color: darken(#ffe400, 10%) !important;
  }

  span.highlight {
    background-color: #daef47;
    color: black;
  }

  span.normal {
    color: #434a54;
  }

  .product-list {
    list-style: square inside;
    padding-left: 0;
    color: #c2c4c5;
  }

  .infospan {
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: #ccc;
  }

  @media (max-width: 768px) {
    .dc-list-filter-container {
      grid-template-columns: 65px 1fr 1fr;
    }
    .dc-list-group-actions {
      grid-column: 3/4;
      grid-row: 1/2;
      // justify-content: end;
      /*padding-left: 1rem;*/
      // padding-top: 0.5rem;
    }
  }

  @media (max-width: 576px)
  {
    .dc-list-filter-buttons {
      grid-column: 1/4;
    }

    .dc-list-filter-select-all {
      display: none;
    }

    .dc-list-filter-container .dc-list-filter-search {
      grid-column: 1/4;
    }

    .dc-list-group-actions {
      display: none;
    }
  }

  @media (min-width: 576px) and (max-width: 992px)  {
    .dc-list-filter-buttons {
      .chip {
        font-size: 0.6rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  @media (min-width: 992px) {
    .dc-list-filter-container {
      position: sticky;
      top: 130px;
      z-index: 6;
      box-shadow: 0 5px 12px -5px rgba(163, 161, 163, 1);
    }
  }

  .btn-outline-light {
    box-shadow: none !important;
  }
}
