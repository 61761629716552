





























































































































































































































































































.cart-list-item {
  display: grid;
  grid-template-columns: 35px 1fr auto 130px;

  &.selected {
    background-color: rgba(186, 223, 255, 0.25);

    &:hover {
      background-color: rgba(186, 223, 255, 0.4);
    }
  }

  .symbol-container {
    flex: 1;
  }

  .cart-list-item-static-quantity {
    font-size: .8rem;
  }

  .cart-list-item-select {
    grid-column: 1;
    grid-row: 2;
  }

  .cart-list-item-image {
    grid-column: 2/5;
    grid-row: 3;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 200px;
      width: auto;
    }

    .drawing-size {
      font-size: 0.7rem;
      font-weight: bold;
    }
  }

  .cart-list-item-title {
    grid-column: 2/5;
    grid-row: 2;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 0.95rem;
    overflow-wrap: anywhere;
  }

  .cart-list-item-details {
    color: #585858;
    grid-column: 2/5;
    grid-row: 4;
    font-size: 0.65rem;
    margin-bottom: 1rem;
  }

  .cart-list-item-price-row {
    grid-column: 1/6;
    justify-self: end;
    min-width: 350px;

    @media(min-width:992px) {
      grid-column: 3/6;
    }
    grid-row: 5;
    max-width: 500px;
  }

  .cart-list-item-price,
  .cart-list-item-quantity,
  .cart-list-item-subtotal {
    flex: 3;
  }

  // .cart-list-item-quantity {
  //   justify-self: flex-end;
  //   align-items: center;
  //   display: flex;
  // }

  // .cart-list-item-price-container {
  //   grid-column: 4;
  //   grid-row: 5;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   font-size: .75rem;

  //   .cart-list-item-price {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //     align-items: flex-end;
  //   }
  // }

  // .cart-list-item-subtotal {
  //   font-size: 0.9rem;
  //   grid-column: 3/5;
  //   grid-row: 6;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  //   justify-content: center;
  // }

  .cart-list-item-standard-actions-container {
    grid-row: 1;
    grid-column: 4;
    justify-self: flex-end;

    .btn {
      min-width: auto;
      color: rgba(0, 0, 0, 0.26);

      &:hover {
      color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  @media (min-width: 576px) {
    .cart-list-item-details {
      grid-column: 3;
      grid-row: 3;
      font-size: 0.65rem;
    }
  }

  @media (min-width: 576px) {
    grid-template-columns: 30px 150px 1fr 130px 130px;

    .cart-list-item-select {
      grid-column: 1;
      grid-row: 1;
    }

    .cart-list-item-standard-actions-container {
      grid-column: 5;
      grid-row: 1;
    }

    .cart-list-item-title {
      grid-column: 3/5;
      grid-row: 1;
    }

    .cart-list-item-image {
      grid-column: 2/3;
      grid-row: 1/4;
    }

    .cart-list-item-details {
      grid-column: 3/6;
      grid-row: 2/4;
    }

    // .cart-list-item-quantity {
    //   grid-column: 2/4;
    //   grid-row: 4;
    //   justify-self: flex-end;
    // }

    // .cart-list-item-price-container {
    //   grid-column: 4;
    //   grid-row: 4;
    // }

    // .cart-list-item-subtotal {
    //   grid-column: 5;
    //   grid-row: 4;
    // }
  }

  @media (min-width: 768px) {
    grid-template-columns: 30px 100px 1fr;

    .cart-list-item-select {
      grid-column: 1;
      grid-row: 1;
    }

    .cart-list-item-standard-actions-container {
      grid-column: 3;
      grid-row: 1;
      justify-self: flex-end;
    }

    .cart-list-item-title {
      grid-column: 3;
      grid-row: 2;
    }

    .cart-list-item-image {
      justify-self: flex-start;
      grid-column: 2;
      grid-row: 2;
    }

    .cart-list-item-details {
      grid-column: 3;
      grid-row: 3;
    }

  //   .cart-list-item-quantity {
  //     grid-column: 2;
  //     grid-row: 4;
  //     justify-self: flex-end;
  //   }

  //   .cart-list-item-price-container {
  //     grid-column: 3;
  //     grid-row: 4;
  //     justify-self: flex-end;
  //   }

  //   .cart-list-item-subtotal {
  //     grid-column: 3;
  //     grid-row: 5;
  //     justify-self: flex-end;
  //   }
  }

@media (min-width: 992px) {
  grid-template-columns: 30px 150px 1fr 130px 130px;

    .cart-list-item-select {
      grid-column: 1;
      grid-row: 1;
    }

    .cart-list-item-standard-actions-container {
      grid-column: 5;
      grid-row: 1;
    }

    .cart-list-item-title {
      grid-column: 3/5;
      grid-row: 2;
    }

    .cart-list-item-image {
      justify-self: flex-start;
      grid-column: 2/3;
      grid-row: 2/4;
    }

    .cart-list-item-details {
      grid-column: 3/6;
      grid-row: 3/4;
    }

//     .cart-list-item-quantity {
//       grid-column: 3;
//       grid-row: 5;
//       justify-self: flex-end;
//     }

//     .cart-list-item-price-container {
//       grid-column: 4;
//       grid-row: 5;
//     }

//     .cart-list-item-subtotal {
//       grid-column: 5;
//       grid-row: 5;
//     }
  }
}
