








































































.drawing-product-batch-list.form-group label {
  width: 100%
}
