













































































































































































.dashboard-view {
  h2 {
    font-size: 1.2rem;
  }
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .block {
    width: calc(50% - 1rem);

    @media(max-width: 1068px) {
      width: 100%;
    }
  }
}

