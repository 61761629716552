










































.determinate-progress {
  .progressCircle {
    stroke: #000000;
    stroke-width: 3px;
    fill: none;
  }

  .backgroundCircle {
    stroke: #ccc;
    stroke-width: 3px;
    fill: none;
  }
}
