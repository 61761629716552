
























































































































































.cart-custom-list-item {
  grid-template-columns: 35px auto 130px;

  .cart-list-item-title {
    grid-column: 1/4;
    grid-row: 1;
  }

  .cart-list-item-quantity {
    grid-column: 1;
    grid-row: 2;
    justify-self: flex-end;
  }

  .cart-list-item-price-container {
    grid-column: 2;
    grid-row: 2;
  }

  .cart-list-item-subtotal {
    grid-column: 3;
    grid-row: 2;
    justify-self: flex-end;
  }

  @media (min-width: 992px) {
    grid-template-columns: 30px 1fr 1fr 130px 130px;

    .cart-list-item-title {
      grid-column: 3/6;
    }

    .cart-list-item-quantity {
      grid-column: 3;
    }

    .cart-list-item-price-container {
      grid-column: 4;
    }

    .cart-list-item-subtotal {
      grid-column: 5;
    }
  }
}
