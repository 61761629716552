



























































.cart-comment-widget {
  textarea {
    padding: .2rem .3rem !important;
  }
}
