































































































































































































































































































































































































































































































































































































































































































































































































































































.shippingForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .invalid-feedback {
    margin-top: 0;
  }
}

.postcodeRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
