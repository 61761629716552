.filter-buttons {
  margin-top: 1em;
  margin-bottom: 10px;
}

.sidebar-filters {
  background-color: #ffffff;
  padding: 1.5em;
}

.card-col {
  display: flex;
  padding: 5px;
}

.material-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  padding-left: 40px;
}

.card.material .card-body {
  height: auto;
  min-height: 75px;
  overflow: hidden;
  padding: 10px;

  h5 {
    letter-spacing: 0;
  }
}

.card-material h5 {
  letter-spacing: 0;
}

.material-show {
  img {
    padding-bottom: 20px;
    width: 100%;
  }

  table {
    background: none;

    td,
    th {
      padding-bottom: 10px;
    }
  }

  .menuLeft {
    .card-col {
      padding-bottom: 20px;
    }
  }

  .title {
    padding: 13px;
    width: 100%;
  }
}

.filter-buttons .btn {
  font-weight: 400;
}

.material-filter {
  padding-left: 0;

  label {
    display: block;
  }

  .custom-control {
    min-height: 0;
  }
}

.btn-color-filter {
  border-radius: 0;
  box-shadow: 0 0 12px 0 rgba(227, 227, 227, 1);
  display: block;
  float: right;
  height: 30px;
  margin-top: 4px;
  margin-right: 5px;
  margin-bottom: 4px;
  margin-left: 5px;
  padding: 3px;
  width: 30px;
}

.slider {
  margin: 1em;
  margin-top: 2.5em;
}

.color-filter {
  max-width: 160px;
  overflow: hidden;
}

.transparency-filter {
  margin-bottom: 0;
}

.btn-color-filter.selected i {
  color: #000000;
  display: inline-block;
}

.btn-color-filter.selected.inverse i {
  color: #ffffff;
  display: inline-block;
}

.btn-color-filter i {
  display: none;
}
