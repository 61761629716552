




























































.Cookie {
  .text-underline {
    text-decoration: underline;
  }
  &.Cookie--mytheme {
    background-color: #333;
    color: #fff;
  }
  .Cookie__buttons {
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
  }
}
