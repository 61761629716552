











* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-muted {
  color: #585858 !important;
}

.card {
  .card-body {
    padding: 0;
    &.default-padding {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &.default-padding-bottom {
      padding-bottom: 1rem;
    }
  }
}

.material-icons.compact {
  line-height: inherit;
}

.input-row-full {
  width: 100%;
  padding: 0.5rem 1rem;
}

.input-row-half {
  width: 50%;
  padding: 0.5rem 1rem;
}

.row-stick-bottom {
  width: 100%;
  flex-grow: 1;
  align-self: flex-end;
  padding: 1rem;
}

html body .vuedals {
  transition: none !important;
}

html body .vuedals .vuedal {
  transition: none !important;
}

ul.ul-snijlab {
  margin: 0.5rem 1rem 0.5rem 0.75rem;
  list-style-type: '●';
  list-style-position: outside;
  padding-left: 0.5rem;

  li {
    padding-left: 0.5rem;
  }
}

.bg-dark.list-group-item-action:active,
.bg-dark.list-group-item-action:focus,
.bg-dark.list-group-item-action:hover {
  color: #fff;
}

.custom-select {
  font-size: 15px;
  font-weight: 300;
  color: rgb(68, 68, 68);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 4px;
  margin-bottom: 8px;
}

.custom-select option {
  font-size: 15px;
  font-weight: 300;
}

$bg: #f5f5f5;

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 3rem;
  display: block;

  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: 0.875rem 0 0 0.875rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border-color: #41B883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }

  &:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  &:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}

.multiselect__loading-transition {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: lighter;
}

.multiselect {
  box-sizing: content-box;

  * {
    box-sizing: border-box;
  }

  display: block;
  position: relative;
  width: 100%;
  min-height: 1rem;
  text-align: left;
  color: #35495E;

  &:focus {
    outline: none
  }

  &--active {
    z-index: 50;

    .multiselect__current,
    .multiselect__input,
    .multiselect__tags {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .multiselect__select {
      transform: rotateZ(180deg);
    }
  }
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 1rem;
  line-height: 1rem;
  border: none;
  width: auto;
  box-sizing: border-box;

  &:hover {
    border-color: darken(#E8E8E8, 10%)
  }

  &:focus {
    border-color: darken(#E8E8E8, 25%);
    outline: none;
  }
}

.multiselect__tags {
  min-height: 1rem;
  display: block;
  padding: 4px 2.5rem 4px 4px;
  background: $bg;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);

  input {
    background: $bg;
  }
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 0.25rem 1.625rem 0.25rem 0.625rem;
  border-radius: 0.3125rem;
  margin-right: 0.625rem;
  color: #fff;
  line-height: 1;
  background: #41B883;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 1.375rem;
  text-align: center;
  line-height: 1.375rem;
  transition: all 0.2s ease;
  border-radius: 0.3125rem;

  &:after {
    content: "\00D7";
    color: darken(#41B883, 20%);
    font-size: 0.875rem;
  }

  &:focus, &:hover {
    background: darken(#41B883, 8%);

    &:after {
      color: white;
    }
  }
}

.default-padding {
  padding: 1rem;
}

.multiselect__current {
  line-height: 1rem;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 0.5rem 1.875rem 0 0.75rem;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.multiselect__select {
  color: rgb(68, 68, 68);
  line-height: 1rem;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 1.5rem;
  height: 1.2rem;
  right: 1px;
  top: 1px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:before {
    position: relative;
    right: 0;
    top: 65%;
    color: rgb(68, 68, 68);
    margin-top: 0.25rem;
    border-style: solid;
    border-width: 0.3125rem 0.3125rem 0 0.3125rem;
    border-color: rgb(68, 68, 68) transparent transparent transparent;
    content: "";
  }
}

.multiselect__placeholder {
  color: #ADADAD;
  display: inline-block;
  margin-bottom: 0.125rem;
  padding-top: 0.125rem;

  .multiselect--active & {
    display: none;
  }
}

.multiselect__content {
  background-color: $bg;
  position: absolute;
  list-style: none;
  display: block;
  width: 100%;
  min-width: 300px;
  max-height: 15rem;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 1px solid #E8E8E8;
  border-top: none;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  z-index: 50;

  &::webkit-scrollbar {
    display: none;
  }
}

.multiselect__option {
  display: block;
  padding: 4px;
  line-height: 1rem;
  font-weight: 300;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;

  .option__aka {
    color: #999;
    text-align: right;
  }

  .option__disabled {
    color: indianred;
    text-align: right;
  }

  &:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 2.5rem;
    padding-right: 0.75rem;
    padding-left: 1.25rem;
  }

  &--highlight {
    background: #41B883;
    outline: none;
    color: white;
  }

  &--selected {
    background: #F3F3F3;
    color: #35495E;
    font-weight: bold;
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #41B883;
  color: #fff;
  font-weight: lighter;
}

.multiselect--disabled {
  pointer-events: none;

  .multiselect__current,
  .multiselect__select {
    background: darken($bg, 7%);
    color: darken($bg, 35%);
  }
}

.multiselect__option--disabled {
  background: darken(#fff, 7%);
  color: darken(#fff, 35%);
  cursor: text;
  pointer-events: none;

  &:visited {
    color: darken(#fff, 35%);
  }

  &:hover,
  &:focus {
    background: darken(#41B883, 3%)
  }
}

.multiselect-transition {
  transition: all .3s ease
}

.multiselect-enter, .multiselect-leave {
  opacity: 0;
  max-height: 0;
}

.card-block .card-title {
  margin-bottom: 0;
}

.card-block-title.card-block {
  display: flex;
  .card-title {
    width: 100%;
  }
  .loading-widget {
    width: 50px;
    margin-top: 5px;
  }
}
.card-title {
  font-size: 0.9rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
