























































































.contentful-jumbotron {
  margin-bottom: 3rem;
  position: relative;

  h1, h2 {
    font-weight: 700;
  }

  &.isLastElement {
    margin-bottom: 0;
  }

  .jumbotron {
    padding: 0;
    color: white;
    // background-size: cover;

    .bg-img-container {
      position: absolute;
      z-index: 7;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: rgb(15, 15, 15);

      img {
        opacity: .25;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .center-container {
      position: relative;
      z-index: 8;
      max-width: 1000px;
      margin: auto;
      display: grid;
      grid-template-columns: auto 550px;
      // padding: 0 1rem 5rem 0;
      padding: 1rem 1rem 2rem 1rem;

      .title-container {
        padding-top: 4rem;
        grid-column: 1/3;
        grid-row: 1;
        align-self: start;
        padding-top: 4rem;
      }

      .text-container {
        grid-column: 1;
        grid-row: 2;
        align-self: start;
      }

      img {
        align-self: start;
        grid-column: 2;
        grid-row: 2;
        max-width: 550px;
      }
    }

    @media(max-width: 991px) {
      margin-bottom: 2rem;

      h2 {
        font-size: 1.8rem;
        font-weight: 700;
      }

      .center-container {
        grid-template-columns: 100%;
        grid-template-rows: auto 1fr;
        padding-top: 4rem;
        padding-bottom: 2rem;

        .title-container {
          grid-column: 1;
          grid-row: 1;
          padding: 1rem 1rem;
        }

        .text-container {
          font-size: .8rem;
          grid-column: 1;
          grid-row: 3;
          padding: 0rem 1rem;

          .btn {
            font-size: .8rem;
          }

        }
        img {
          grid-column: 1;
          grid-row: 2;
          width: 100%;
          padding: 0 1rem;
        }
      }
    }
  }
}
