
























































































































































.recent-upload-item {
  .img-col {
    width: 100px;
  }
}
