


























































































































































































































































































































































































































.dashboard-previous-orders-block {
  th, td {
    padding: .2rem !important;
    height: auto !important;

    .shipping-method {
      font-size: 0.6rem;
    }
  }

  .progress-bar {
    &::after {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  .dropdown-toggle {
    font-size: .8rem;
    padding: .3rem;
    &::after {
      line-height: .8rem;
    }
  }
  .dropdown-item {
    font-size: 0.8rem;
    padding: 0 5px;
  }
}
