







































































































.cart-review-table-order-drawing-item {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}
