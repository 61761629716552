

















































.indeterminate-progress {
  .progressCircle {
    stroke: #000000;
    fill: none;
  }

  .backgroundCircle {
    stroke: none;
    fill: none;
  }

  @keyframes spin-clockwise {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  &.spin-clockwise {
    animation: spin-clockwise 2s infinite linear;
  }
}
