






































































































.contentful-text-with-gallery {

  h2 {
    font-size: 42px;
    font-weight: 300;
    line-height: 54px;
    text-align: center;
  }

  .content-container {
    padding-bottom: 3rem;
    max-width: 1000px;
    margin: auto;

    .text-container {
      padding-right: 0;
      padding-left: 1rem;

      z-index: 2;
      grid-column: 1;
      grid-row: 1;
      h2 {
        font-weight: 700;
      }
    }

    .images-container {
      grid-column: 1/3;
      grid-row: 1;
      width: 100%;
      min-height: 300px;
      overflow: hidden;
      z-index: 1;

      img {
        position: absolute;
        padding: 0;
        margin: 0;
        width: 350px;
        animation-fill-mode: forwards;
      }
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @keyframes fadeout {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    @media(max-width: 767px) {
      grid-template-columns: auto;
      grid-template-rows: 1fr auto;
      padding-bottom: 0;

      .text-container {
        grid-column: 1;
        grid-row: 1;
        font-size: .8rem;
        padding: 0 1rem 1rem 1rem;

        h2 {
          margin-bottom: 1.5rem;
          font-size: 1.8rem;
        }
      }

      .images-container {
        grid-column: 1;
        grid-row: 2;
        width: 100%;
        position: relative;
        min-height: 380px;
      }
    }
  }
}
