


























































































.drawing-configuration-price-matrix {
  .bulk-discount-line {
    font-weight: 100;
    width: 100%;
    span {
      flex: 3;
      text-align: center;
    }
    span:nth-of-type(2),
    span:nth-of-type(4) {
      flex: 1;
    }
    .request-quote-text {
      flex: 7 !important;
    }
  }
  // .bulk-discount-quantity,
  // .bulk-discount-price-piece,
  // .bulk-discount-price-total {
  //   text-align: center;
  //   // width: 30%;
  // }

  // .bulk-discount-price-piece {
  //   &::before {
  //     padding-right: 2rem;
  //     content: "×";
  //   }
  //   &::after {
  //     padding-left: 2rem;
  //     content: "=";
  //   }
  // }
}
