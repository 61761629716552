





















































































































































































































.dashboard-recent-uploads-block {
  .recent-upload-container {
    table {
      th, td {
        padding: .2rem !important;
        height: auto !important;

        .shipping-method {
          font-size: 0.6rem;
        }
      }
      .recent-upload-item {
        .img-container {
          max-width: 100px;
        }
      }
    }
  }
}
