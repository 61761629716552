




















































































































































































































































































































































































  .material-select-drilldown {

    .material-image.material-image-unavailable {
      opacity: 0.5;
    }

    .material-category-family {
      .text-muted {
        color: #b4b4b4 !important;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b4b4b4 !important;
        opacity: 1; /* Firefox */
        font-weight: normal !important;
        font-size: 15px;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #b4b4b4 !important;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #b4b4b4 !important;
      }
    }

    .product-info-material-selected {
      flex: 1;
    }
    .material-option-dropdowns-container {
      flex: 1;
    }
    .selected-material-overview {
      flex: 1;
      // width: 100%;
      // display: flex;
      // flex: 1;
      // display: grid;
      // grid-template-columns: auto;
      // grid-template-rows: auto auto;

      .switch-material-btn {
        // align-self: flex-end;
        // grid-row: 1;
        // grid-column: 1;
        // justify-self: end;
      }

      .material-name-and-image {
        // flex: 1;
        // grid-row: 2;
        // grid-column: 1;
        // min-height: 150px;

        img {
          width: 130px;
          height: 130px;
        }
      }
    }

// @media(max-width: 767px) {
//       .selected-material-overview {

//         .switch-material-btn {
//           grid-row: 1;
//           justify-self: start;
//         }

//         .material-name-and-image {
//           grid-row: 2;
//         }
//       }
//     }

    .material-list-family-name {
      cursor: default;
    }
    .material-list-item {
      &:hover {
        cursor: default;
      }
      &:hover:not(.disabled){
        cursor: pointer;
        font-weight: 400;
      }
      &.cursor-default {
        cursor: default;
      }
    }

    .hide-scrollbar{
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .hide-scrollbar::-webkit-scrollbar{
      background-color: white;
      border-radius: 10px;
      width: 20px;
    }

    .hide-scrollbar::-webkit-scrollbar-thumb{
      background-color: red;
      border-radius: 10px;
      border: 5px solid white;
    }

    .hide-scrollbar::-webkit-scrollbar-thumb:vertical{
      height: 20px!important;
      width: 20px;
    }
  }
