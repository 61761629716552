





































































.dc-editor-bottom-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    .dc-nav-left,
    .dc-nav-right {
      flex: 1;
    }

    .dc-nav-center {
      order: 1;
      flex: 1 100%;
      margin-bottom: .5rem;
    }

    .dc-nav-left {
      order: 2;
      justify-content: flex-start;
    }

    .dc-nav-right {
      order: 3;
      justify-content: flex-end;
    }
  }

  .custom-select option {
    font-size: 15px;
    font-weight: 300;
  }

  .dc-nav-center {
    vertical-align: bottom;
    text-align: center;
    display: none;
  }

  .dc-nav-left,
  .dc-nav-right {
    position: relative;
    display: flex;
  }

  .dc-nav-center {
    vertical-align: bottom;
    width: 200px;
    text-align: center;
  }

  @media (min-width: 370px) {
    .dc-nav-center {
      display: block;
    }
  }
}
