



















































// .help-modal {
  .modal-header .close {
    z-index: 1;
  }

  .help-modal-content a.hreflink i.material-icons {
    display: none;
  }

  .help-modal-content {
    img {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 100%;
      font-size: 1rem;

      .fix-link::before {
        display: none;
      }
    }

    h1 {
      font-size: 1.2rem;
    }

    ul {
      margin-top: 1rem;
      padding-left: 1.25rem;
    }
  }
// }
