







































.drawing-configuration-order-list {
  .order-list-more {
      cursor: pointer;
  }

  .order-list {
      display: inline;
      list-style: none;
      padding: 0;
  }

  .order-list-item-invisible {
      visibility: hidden;
      display: none;
  }

  .order-list .order-list-item {
      display: inline;
  }
  .order-list .order-list-item:before {
    display: none;
  }

  .order-list .order-list-item:after {
      content: ", ";
  }

  .order-list .order-list-item:last-child:after {
      content: "";
  }

  .order-list-more:hover {
      background-color: #ccc;
  }
}
