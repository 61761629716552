







































































































.order-priority-list .subtotal-title {
  float: right;
}
