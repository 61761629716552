























































































































.drawing-product-group {
  label {
    cursor: default;
  }
 }
