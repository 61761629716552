














































































































































































































































































































.submit-request-quote-modal-btn {
  color: white !important;
  background-color: #00b900 !important;
}

.request-quote-modal-content {
  .b-modal-target.vue-portal-target > div {
    position: fixed;
    z-index: 2000 !important;
  }
  .quote-incentive{
    margin-top: 20px;
  }

  .form-container {

    .asterisk {
      color: red;
    }

    .form-error {
      font-size: 0.8rem;
    }

    .form-group--error {
      label {
        color: red;
      }
      input,
      textarea,
      select {
        border: 1px solid red !important;
      }
    }
  }
}
