



















































































































































































.dashboard-contentful-block {
  iframe {
    width: 100%;
  }
  ul {
    list-style: none;
    padding: 0 0 0 1rem;

    li:before {
      font-weight: bold;
      content: '✓';
      text-indent: 0;
      float: left;
      padding: 0 .5rem 0 0;
    }

    li p {
      padding: 0;
      margin: 0;
    }
  }
}
