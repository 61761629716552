.snijlab {
  font-family: 'Fira Sans', sans-serif;
  // font-family: 'DIN Next', sans-serif;
  font-size: 16px;
  font-weight: 400;
  // font-size: 0.8rem;
}

.shadow-light-right-only {
  box-shadow: 2px 1px 11px 0 rgba(0, 0, 0, 0.2);
}

.shadow-light {
  background-color: #ffffff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.shadow-soft {
  background-color: #ffffff;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.49);
}

.no-padding {
  padding: 0;
}

.mainContent {
  ol li,
  ul li {
    font-weight: 300;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
    }
  }
}

.text-content a:not(.btn) {
  color: $color-danger;
}

.container-fluid {
  margin: 0;
  padding: 0;

  .row {
    margin: 0;
    padding: 0;
  }
}
