


























































































































































































































.hreflink {
  display: none;
}

.main-container.container,
.main-container.container-fluid {
  min-height: calc(80vh - 75px);

  &.and-message-bar {
    @media (min-width: 768px) {
      padding-top: 50px;
    }

    .fix-link::before {
      height: 110px;
    }

    .collapse-toggle.fix-link::before {
      height: 0px;
    }

    h2.fix-link::before,
    h3.fix-link::before {
      height: 75px;
    }
  }
}

.top-buffer {
  margin-top: 75px;
}

.btn-admin,
.bg-admin {
  background-color: #ffe400;
  color: #000;
}

.border-snijlab {
  border: 1px solid #ffe400;
}

.info-icon {
  color: rgb(160, 160, 160);
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.pointer {
  cursor: pointer;
}

html {
  width: 100%;
  overflow-x: hidden;

}

label {
  font-size: 15px;
}

.input-icon-front {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.2rem;
}

.input-icon {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0.2rem;
}

.push-down {
  margin-top: 0px;

  .snlb-sticky-flex {
    top: 139px;
  }
}

@media (max-width: 578px) {
  .modal-body, .modal-header {
    padding: 0.625rem 0.75rem
  }
}

.form-control.has-icon {
  padding-right: 25px;
}

html body .vuedals {
  z-index: 200;
}

.vuedals .vuedal.lg {
  position: relative;
}

.vuedals .vuedal.borderless {
  padding: 0;

  header {
    display: none;
  }
}

.vuedals .vuedal.xl {
  width: calc(100vw - 3rem);
  margin-top: 2.5em;
  position: relative;

  @media (max-width: 768px) {
    width: calc(100vw - 1rem);
    padding: 10px;
  }
}

.vuedals .vuedal.xs {
  width: calc(40vw);
  margin-top: 2.5em;
  position: relative;
}

.vuedals header {
  margin-bottom: 0;
  border: 0;
}

.vuedals .vuedal {
  margin: 0 auto;
  transform: none;
  left: initial;
}
