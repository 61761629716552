



































































































































































































































































































































































.dc-list-item {
  border-bottom: 1px dotted #ddd;
  display: grid;
  grid-template-columns: 395px 1fr 450px;
  box-sizing: border-box;

  .drawing-size {
    font-size: 0.7rem;
    font-weight: bold;
  }

  &:hover {
    background-color: rgb(250,250,250);
  }

  &.selected {
    background-color: rgba(186, 223, 255, 0.25);
    &:hover {
      background-color: rgba(186, 223, 255, 0.4);
    }
  }

  .dc-list-item-price {
    .btn {
      width: 150px;
    }
  }

  .order-list {
    padding-left: 0;
  }

  .dc-list-item-image {
    img {
      max-height: 200px;
      width: auto;
      max-width: 100%;
    }
  }

  .dc-list-item-select .custom-control.custom-checkbox {
    min-height: auto;
  }

  .dc-list-item-select,
  .dc-list-item-id,
  .dc-list-item-specification {
    cursor: pointer;
  }

  // .dc-list-item-actions .row-actions {
  //   width: 100%;
  // }

  .dc-list-item-specification {
    // padding: 17.5px 10px 10px;
    overflow-wrap: anywhere;
  }
}

.btn-empty {
  border-bottom: 1px solid #eeeeee;
}

.dc-list-item-first-column {
  display: grid;
  grid-template-columns: 75px 100px 220px;
}

.dc-list-item-first-column > .dc-list-item-select {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dc-list-item-first-column > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dc-list-item-image {
  padding: 10px;
  display: flex;
  justify-content: center;
  cursor: zoom-in;
}

.dc-list-item-last-column {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 165px 150px 170px;
}

.dc-list-item-last-column > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// @media (min-width: 1200px) {
//   .dc-list-item {
//     padding-right: 15px;
//   }
// }

// @media (max-width: 1200px) {
//   .dc-list-item {
//     display: grid;
//     grid-template-columns: 395px 1fr 300px;
//   }
//   .dc-list-item-last-column {
//     display: grid;
//     align-items: center;
//     justify-content: center;
//     grid-template-columns: 165px 125px;
//     grid-template-rows: 1fr 1fr;
//   }
//   .dc-list-item-cart {
//     grid-column: 1 / 2;
//     grid-row: 1 / 2;
//     /*border: 1px solid;*/
//     align-items: flex-end;
//     padding-bottom: 15px;
//   }
//   .dc-list-item-price {
//     grid-column: 1 / 2;
//     grid-row: 2 / 3;
//     display: flex;
//     align-items: flex-start;
//     /*border: 1px solid;*/

//   }
//   .dc-list-item-actions {
//     grid-column: 2 / 3;
//     grid-row: 1 / 3;
//   }
// }

// @media (max-width: 992px) {
//   .dc-list-item {
//     display: grid;
//     grid-template-columns: 220px 1fr auto;
//   }
//   .dc-list {
//     margin: 0;
//   }

//   .dc-list-item-first-column {
//     grid-template-columns: 65px 170px;
//     grid-template-rows: 50px 1fr;
//   }

//   .dc-list-item-first-column > .dc-list-item-select {
//     justify-content: flex-start;
//   }
//   .dc-list-item-last-column {
//     // display: grid;
//     // grid-template-columns: 1fr;
//     // grid-template-rows: 1fr 1fr;
//     display: flex;
//     flex-direction: column;
//     // align-content: flex-end;
//     align-items: flex-end;
//     // justify-content: flex-end;
//     justify-items: flex-end;
//     border: 1px solid red;
//   }
//   .dc-list-item-price {
//     height: auto;
//     justify-self: flex-end;
//     // grid-row: 1;
//     // grid-column: 1;
//   }
//   .dc-list-item-actions {
//     height: auto;
//     justify-self: flex-end;
//     // grid-row: 2;
//     // grid-column: 1;
//   }
//   .dc-list-item-first-column > .dc-list-item-id {
//     grid-row: 1 / 2;
//     grid-column: 2 / 3;
//     display: flex;
//     justify-content: flex-start;
//   }

//   .dc-list-item-first-column > .dc-list-item-image {
//     grid-row: 2 / 3;
//     grid-column: 1 / 3;
//     display: flex;
//     padding: 10px 10px 20px;
//   }
// }
@media (min-width: 1201px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 30px 150px 1fr 200px;
    grid-template-rows: 30px auto 1fr;
  }
  .dc-list-item-select {
    grid-column: 1;
    grid-row: 1;
    width: 30px;
  }
  .dc-list-item-id {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    padding: 0 !important;
    padding: 0;
  }
  .dc-list-item-specification {
    grid-column: 3;
    grid-row: 2/4;
    padding: 0 !important;
  }
  .dc-list-item-image {
    grid-column: 1/3;
    grid-row: 2/4;
    display: flex;
    align-items: flex-start;
  }

  .dc-list-item-price {
    grid-column: 3;
    grid-row: 3;
    margin-top: 20px;
  }
  .dc-list-item-actions {
    grid-column: 4;
    grid-row: 3;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-self: flex-end;
  }
  .dc-list-item-cart {
    grid-column: 4;
    grid-row: 2;
    justify-self: flex-end;
    padding: 0;
  }
}
@media (max-width: 1200px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 30px 150px 1fr 200px;
    grid-template-rows: 30px auto 1fr;
  }
  .dc-list-item-select {
    grid-column: 1;
    grid-row: 1;
    width: 30px;
  }
  .dc-list-item-id {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    padding: 0 !important;
    padding: 0;
  }
  .dc-list-item-specification {
    grid-column: 3;
    grid-row: 2/4;
    padding: 0 !important;
  }
  .dc-list-item-image {
    grid-column: 1/3;
    grid-row: 2/4;
    display: flex;
    align-items: flex-start;
  }

  .dc-list-item-price {
    grid-column: 3;
    grid-row: 3;
    margin-top: 20px;
  }
  .dc-list-item-actions {
    grid-column: 4;
    grid-row: 3;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-self: flex-end;
  }
  .dc-list-item-cart {
    grid-column: 4;
    grid-row: 2;
    justify-self: flex-end;
    padding: 0;
  }
}
@media (max-width: 992px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 30px 150px 1fr;
    grid-template-rows: 30px 1fr;
  }
  .dc-list-item-select {
    grid-column: 1;
    grid-row: 1;
    width: 30px;
  }
  .dc-list-item-id {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    padding: 0 !important;
    padding: 0;
  }
  .dc-list-item-specification {
    grid-column: 3/4;
    grid-row: 2/3;
    padding: 0 !important;
  }
  .dc-list-item-image {
    grid-column: 1/3;
    grid-row: 2/4;
    display: flex;
    align-items: flex-start;
  }

  .dc-list-item-price {
    grid-column: 3/4;
    grid-row: 3;
    margin-top: 20px;
  }
  .dc-list-item-actions {
    grid-column: 3/4;
    grid-row: 5;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-self: flex-end;
  }
  .dc-list-item-cart {
    grid-column: 3/4;
    grid-row: 4;
    justify-self: flex-end;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 30px 150px 1fr;
    grid-template-rows: 30px 1fr;
  }
  .dc-list-item-select {
    grid-column: 1;
    grid-row: 1;
    width: 30px;
  }
  .dc-list-item-id {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    padding: 0 !important;
    padding: 0;
  }
  .dc-list-item-specification {
    grid-column: 3/4;
    grid-row: 2/3;
    padding: 0 !important;
  }
  .dc-list-item-image {
    grid-column: 1/3;
    grid-row: 2/4;
    display: flex;
    align-items: flex-start;
  }

  .dc-list-item-price {
    grid-column: 3/4;
    grid-row: 3;
    margin-top: 20px;
  }
  .dc-list-item-actions {
    grid-column: 3/4;
    grid-row: 5;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dc-list-item-cart {
    grid-column: 3/4;
    grid-row: 4;
    justify-self: flex-end;
    padding: 0;
  }
}

// @media (max-width: 576px) {
//   .dc-list-item-first-column > .dc-list-item-select {
//     display: none;
//   }
//   .dc-list-item-first-column {
//     padding-left: 15px !important;
//   }
//   .dc-list-item-first-column > .dc-list-item-id {
//     justify-content: flex-start;
//     grid-column: 1/2;
//   }

//   .dc-list-item {
//     font-size: 14px;
//     display: grid;
//     grid-template-columns: minmax(160px, 1fr) 2fr;
//   }

//   .dc-list-item-first-column {
//     grid-template-columns: 50px 1fr;
//   }

//   .dc-list-item-last-column {
//     grid-template-columns: minmax(160px, 1fr) 2fr;
//     padding-right: 5px;
//   }

//   .dc-list-item-price, .dc-list-item-cart {
//     padding-right: 5px;
//     padding-left: 5px;
//   }

//   .dc-list-item-actions {
//     padding-left: 5px;
//   }
//   .dc-list-item-specification {
//     padding-left: 0;
//   }

//   .dc-list-item-actions {
//     padding: 0;
//     margin: 0;
//   }
// ;

//   .dc-list-item-first-column {
//     grid-template-columns: 50px 1fr;
//   }

//   .dc-list-item-image {
//     width: 100%;

//   }

//   .card-header.sidecolumn {
//     display: none;
//   }
//   .card.p-3.m-3.text-center.btn {
//     display: none;
//   }

// }

@media (max-width: 576px) {
  .dc-list-item {
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-template-rows: 30px 1fr;
  }
  .dc-list-item-select {
    grid-column: 1;
    grid-row: 1;
    width: 30px;
  }
  .dc-list-item-id {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-start;
    padding: 0 !important;
    padding: 0;
  }
  .dc-list-item-specification {
    grid-column: 1/3;
    grid-row: 3;
    padding: 0 !important;
  }
  .dc-list-item-image {
    grid-column: 1/3;
    grid-row: 2;
    display: flex;
    align-items: flex-start;
  }

  .dc-list-item-price {
    grid-column: 1/3;
    grid-row: 4;
    margin-top: 20px;
  }
  .dc-list-item-actions {
    grid-column: 1/3;
    grid-row: 6;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dc-list-item-cart {
    grid-column: 1/3;
    grid-row: 5;
    justify-self: flex-end;
    padding: 0;
  }
}
/*
.dc-list-item-id
.dc-list-item-image
.dc-list-item-specification
.dc-list-item-price
.dc-list-item-cart
.dc-list-item-actions*/
