
























































































































































































  .signin-form {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .login-btn {
      margin: auto;
      width: 300px;
      max-width: 300px;
      max-height: 50px;

      .login-text {
        padding: 13px;
      }
    }

.invalid-feedback {
      background-color: #fff;
      font-weight: bold;
      padding: 4px;
    }
  }
