























































































































































































































































































































.thank-you-page {
  .address {
    display: block;
    border :1px solid #f2f2f2;
    margin: .5rem;
    padding: .5rem;
    max-width: 350px;
  }

  img {
    width: 100%;
  }

  a.hreflink i.material-icons {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 100%;
    font-size: 1rem;

    .fix-link::before {
      display: none;
    }
  }

  h1 {
    font-size: 1.2rem;
  }

  ul {
    margin-top: 1rem;
    padding-left: 1.25rem;
  }
}
