

































.message-bar {
  background-color: #ffe400;
  display: flex;
  font-size: 0.75rem;
  justify-items: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;

  &.message-bar-relative {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 768px) {
    height: 50px;
    &.message-bar-relative {
      display: none;
    }
  }

  .message {
    align-self: center;
  }

  .material-icons {
    line-height: inherit;
  }

  .close {
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }
}
