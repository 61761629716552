



































































































































































































































































.order-payment-view {

  .payment-status-title {
    font-size: 1.4rem;
  }

  .summary-card {

    table {
      width: 100%;
    }

    tr {
      border-bottom: 1px solid rgb(230, 230, 230);
    }

    td {
      padding-top: .1rem;
      padding-bottom: .1rem;
    }

    .total-row {
      border-bottom: none;
      td {
        padding-top: 0.2rem;
      }
    }
  }
}
