


























































































































































.drawing-configuration-price-button {
  flex: 3;
  font-size: 18px;

  .single-price-striketrough {
    text-decoration: line-through;
    color: rgb(117, 117, 117) !important;
  }
}
