// $grid-columns:      12 !default;
$grid-gutter-width: 20px !default;


// $grid-breakpoints: (
//   xs: 0, // was 0
//   sm: 776px, // was 576
//   md: 968px,  // was 768
//   lg: 1192px,  // was 992
//   xl: 1400px  // was 1200
// ) !default;

// @include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
// @include _assert-starts-at-zero($grid-breakpoints);

$container-max-widths: (
  sm: 740px, // was 540
  md: 920px, // was 720
  lg: 1160px, // was 960
  xl: 1340px, // was 1140
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');
