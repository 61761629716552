









































































































































































































































































































































































































































































































.main-menu-wrapper {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;

  .selected-language {
    font-weight: 400;
  }

  .popup-menu {
    z-index: 200;
    .material-icons {
      padding-right: .6rem;
    }
    ul {
      list-style: none;
      text-indent: 0;
      padding: 0;
      margin: 0;
    }
  }

  .dropdown-main-item {
    .item-and-dropdown-container {
      position: relative;
      // overflow: visible;
      .no-pointer {
        cursor: default;
      }
    }
    @media(min-width: 768px) {
      &:hover {
        .dropdown-container {
          display: inline-block;
        }
      }
    }
    .dropdown-container {
      min-width: 220px;
      background-color: #f1f1f1;
      position: absolute;
      display: none;

      @media(min-width: 768px) {
        padding-top: 16px;
      }

      ul li {
        list-style-type: none;
      }

      ul {
        padding: 0;
      }
    }
  }

  .dropdown.no-icon .dropdown-toggle::after {
    display: none;
  }

  .btn-group.no-box {
    box-shadow: none;
  }

  .main-menu-logo {
    width: 10rem;
  }

  .logo-snijlab {
    background-image: url('~@/assets/logo/snijlabweb.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
  }

  .logo-snijlab-grey {
    background-image: url('~@/assets/logo/snijlab-logo-grey.svg');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
  }

  .menu-left {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
  }

  .menu-right {
    width: 100%;
    display: flex;
    // align-items: center;
    // justify-items: flex-end;
    // justify-self: flex-end;
    justify-content: flex-end;
  }

  .menu-popup {
    position: relative;
  }

  .username-item {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem;
  }

  .main-menu-items .menu-popup {
    .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
      display: none;
    }
  }

  .main-menu-items {
    .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
      display: flex;
    }
    padding-left: 0;
  }

  .menu-separator {
    margin: 5px;
    border-bottom: 1px solid #aaaaaa;
  }

  .hamburgerIcon .material-icons {
    font-size: 1.5rem;
  }

  .menu-title{
    font-weight: 500;
  }

  // @media (max-width: 1200px) {
    .main-menu-items {
      .menu-logo.logo-snijlab {
        width: 115px;
        margin-left: 1.1rem;
      }

      .menu-item a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // display: block;
        &.hamburgerIcon {
          display: flex;
        }
      }
    }

    .main-menu {
      .popup-menu .menu-1200 {
        display: flex;
      }
    }
  // }

  @media (max-width: 992px) {
  //   .main-menu .main-menu-items {
  //     .menu-item a {
  //       font-size: 0.7rem;

  //     }
      .menu-logo.logo-snijlab {
        width: 115px;
        margin-left: .8rem;
      }
  //   }
  }

  .popup-menu {
    position: absolute;
    right: 0;
    top: 62px;
    width: 250px;
    z-index: 200;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    ul li {
      list-style-type: none;
    }

    ul {
      padding: 0;
    }

    li.search {
      display: none;
      /*display: flex;*/
      padding: 5px;
    }

    li.language {
      display: flex;
      span {
        padding: 10px;
      }
      a {
        width: 33%;
      }
    }
  }

  @media(max-width: 767px) {

    .menu-left,
    .menu-right,
    .close-btn {
      display: none;
    }

    .menu-right-mobile {
      display: flex;
      justify-content: flex-end;
      .btn {
        min-width: 1px;
      }
    }

    .main-menu.show-mobile-menu {
      z-index: 100;
      width: 100vw;
      height: 100vh;
      background-color: white;
      display: flex;
      flex-direction: column;

      .menu-logo {
        display: none;
      }

      .close-btn {
        position: fixed;
        right: 1rem;
        top: 1rem;
        min-width: 40px;
        max-width: 40px;
        width: 40px;
        min-height: 40px;
        max-height: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .menu-left,
      .menu-right {
        display: flex;
        flex-direction: column;

        .popup-menu {
          position: relative;
          top: 0;
          box-shadow: none;
          background-color: transparent !important;
          background-color: #ffdd00;

          .material-icons {
            margin-top: -3px;
          }

          .menu-item {
            display: block;
          }

          .menu-item-admin {
            background-color: #424242;
            a:link, a:hover, a:active, a:visited {
              color: white;
            }
          }
        }

      }

      .badge-snijlab-inverted {
        background-color: #363636;
        color: #ffdd00;
        height: 1.5rem;
        width: 1.5rem;
      }

      .menu-left .main-menu-items {
        // border-bottom: 1px solid #000;
        margin-top: 80px;

        &.show-mobile-profile-menu {
          border: none;
        }

        &.show-mobile-mat-menu,
        &.show-mobile-profile-menu {
          margin-top: 40px;
        }
      }

      .mobile-mat-menu-back-btn,
      .mobile-profile-menu-back-btn {
        height: 40px;
        background-color: #ffdd00;
      }
      .mobile-profile-menu-back-btn .hello-text {
        padding-top: 2px;
      }

      .main-menu-items {
        .mobile-menu-cart-login {
          .btn {
            font-size: 24px;
            width: 48%;
          }
        }

        flex-direction: column;
        width: 100%;
        max-width: 300px;

        .menu-item {
          display: inline-block;

          .badge {
            margin-top: 0;
            font-size: inherit;
          }

          a:link, a:hover, a:active, a:visited {
            color: #363636;
            font-size: 20px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            padding: .6rem;
          }
        }
      }

      .dropdown-container {
        position: relative;
        background: transparent;
        text-align: center;

        .menu-item {
          margin-left: 1rem;
        }
      }
        .dropdown-main-item.show-mobile-profile-menu {
          width: 100%;
        }

      .popup-menu {
        width: 100%;
        ul {
          padding: 0;
        }
      }

      .menu-lang {
        margin-top: 2rem;
        border-top: 1px solid #000;
        width: 100%;
        min-height: 70px;
        margin: auto;

        a {
          color: #000;
          text-decoration: none;
       }

        .spacer {
          width: 1px;
          height: 40px;
          background-color: #000;
        }
      }
    }
  }

  .menu-lang {
    margin-left: 0.5rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;

    a {
      color: #363636;
      // opacity: 0.8;
    }

    a:hover, a:focus, a:active {
      color: #363636;
    }
    // .divider {
    //   display: none;
    // }
  }

  .main-menu {
    z-index: 101;
    // background-color: #f1f1f1;
    color: #363636;
    height: 75px;
    width: 100vw;
    display: grid;
    grid-template-columns: 2fr 1fr;
    min-width: 280px;
  }

  .main-menu-items {
    margin-top: 15px;
    list-style-type: none;
    display: flex;
    align-items: center;

    .menu-item.menu-cart {
      position: relative;
      padding-right: 0.35rem;
      padding-left: 0.15rem;

      a {
        opacity: 1;
      }

      .badge {
        font-size: .6rem;
        height: 20px;
        margin-top: -10px;
        padding: 2px;
      }
    }

    .menu-logo {
      width: 150px;
      height: 35px;
    }

    .menu-logo a, .menu-logo button {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .menu-search-input {
      align-items: center;
      display: flex;
      color: #363636;
      padding: 0;
      position: relative;
      @media (min-width: 1400px) {
        display: inline-block !important;
      }
    }

    .menu-search-input-el {
      max-width: 150px;
      padding: 10px;
      height: 2.1rem;
      background-color: #fff;

      border: none;
      color: #fff;

      &::placeholder {
        color: #ccc;
      }

      font-weight: lighter;

      &:focus {
        outline: rgba(255, 228, 0, 0.5) 1px solid;
      }
    }

    .menu-item {
      &.menu-sign-in {
        min-width: 120px;
        text-align: center;
        a {
          font-weight: 500;
          color: white !important;
          background: #00b900;
        }
        // padding: 0.2rem;
        border-radius: 2px;
      }

      &.menu-item-upload {
        border: 1px solid #363636;
        min-width: 120px;
        text-align: center;
        a {
          color: #363636;
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;
          opacity: 1;
        }

        border-radius: 2px;
      }

      .material-icons {
        line-height: 1.1rem;
      }

      &.no-fade {
        a {
          opacity: 1;
        }
      }

      a {
        display: flex;
        padding: 0.5rem;
        color: rgb(54, 54, 54);
        // font-weight: lighter;
        font-size: 0.8rem;
        font-weight: 400;
      }

      &:hover {
        color: rgb(54, 54, 54);

        a {
          opacity: 0.8;
          text-decoration: none;
        }
      }

      a:focus, a:hover, a:active {
        text-decoration: none;
      }

      // a:active {
      //   outline: 1px dotted rgba(255, 228, 0, 0.5);
      // }

      // &:focus {
      //   outline: 1px dotted #ccc;
      // }

      &.active {
        a {
          opacity: 1;
          font-weight: bolder;
        }
      }

      &.menu-item-admin {
        background-color: #ffe400;
        a {
          color: #000;
        }
        &:hover {
          background-color: #e6cf00;
        }
      }
    }
  }
}

