






































































































































































.sub-menu {
  @media (min-width: 768px) {
    &.and-message-bar {
      padding-top: 50px;
    }
  }

  .btn-container-center {
    display: flex;
    flex-direction: row;
    align-content: center;

    h1 {
      padding-top: 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  .btn-container {
    margin-top: 2px;
    width: 210px
  }
  .progress-bar-indeterminate.bg-snijlab {
      border-bottom-color: #fff5a3;
  }
  .progress {
      margin-bottom: -5px;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav .nav-link {
    cursor: pointer;
    text-transform: none;
  }

  .btn-container {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
  }
  .btn-container.btn-container-right {
    justify-content: flex-end;
  }

  .btn {
    margin: 5pt 0;
  }

  .nav-item {
    display: flex;
  }

  @media (max-width: 1092px) {
    .btn {
      margin: 3pt 0;
    }
  }

  @media (max-width: 1084px) {

    .nav .btn-container .btn {
      font-size: 0.75rem;
      padding: 0.5rem;
    }

    .btn-container {
      width: 185px;

    }

    .nav .nav-link {
      min-height: 2.25rem;
      padding: 0.75rem;
      font-size: 0.75rem;
    }

    .btn-container.btn-container-right .btn, .btn-container.btn-container-left .btn {
      right: inherit;
      position: relative;
    }

    .btn-container {
      margin-top: 2px;
    }

    .btn-container-center h1 {
      padding-top: 0.70rem;
      font-size: 0.75rem;
      font-weight: 400;
    }

    .btn-container-left {
      justify-content: flex-start;
    }
  }

  @media (max-width: 878px) {
    .nav .btn-container {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .nav {
      padding-left: 0;
      padding-right: 0;
    }

    .nav-item > .nav-link {
      display: none;
    }

    .nav-item > .nav-link.active {
      display: block;
    }
  }

  @media (max-width: 576px) {

    .btn-container {
      width: auto;
    }

    .num {
      display: none;
    }

    .btn-container .btn i.material-icons {
      display: none;
    }

    .nav.nav-tabs {
      justify-content: space-around;

      .btn-container-right-spacer {
        display: none;
      }
      .btn-container-center {
        align-self: flex-start;
      }
      .btn-container-right {
        align-self: flex-end;
        margin: 0;
      }

      .btn-container.btn-cart {
        margin: 0;
      }
    }
  }
}

