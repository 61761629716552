































































































































































































.drawing-configuration-product-list {
  ul {
    line-height: normal;
    text-indent: 0;
  }
  .alert {
    font-size: 0.7rem;
  }
}
