





































































































.payment-widget {

  padding: 0;
  list-style-type: none;

  .list-group-item-action {
    min-height: 0;
  }

  input {
    display: none;
  }

  label {
    position:  relative;
  }

  label.selected {
    background-color: #eeeeee;
  }

  label .radio-icon {
    display: inline-block;
    grid-column: 1;
  }

   .payment-label {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
  }
  label .payment-label-name {
    display: inline-block;
    grid-column: 2;
  }

  label .payment-logo {
    float: right;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: 3;
  }

  .payment-method-mollie-ideal {
    background-image: url('~@/assets/mollie/ideal.png');
  }

  .payment-method-mollie-creditcard {
    background-image: url('~@/assets/mollie/creditcard.png');
  }

  .payment-method-mollie-mistercash {
    background-image: url('~@/assets/mollie/mistercash.png');
  }

  .payment-method-mollie-sofort {
    background-image: url('~@/assets/mollie/sofort.png');
  }

  .payment-method-mollie-banktransfer {
    background-image: url('~@/assets/mollie/banktransfer.png');
  }

  .payment-method-mollie-directdebit {
    background-image: url('~@/assets/mollie/directdebit.png');
  }

  .payment-method-mollie-belfius {
    background-image: url('~@/assets/mollie/belfius.png');
  }

  .payment-method-mollie-paypal {
    background-image: url('~@/assets/mollie/paypal.png');
  }

  .payment-method-mollie-bitcoin {
    background-image: url('~@/assets/mollie/bitcoin.png');
  }

  .payment-method-mollie-podiumcadeaukaart {
    background-image: url('~@/assets/mollie/podiumcadeaukaart.png');
  }

  .payment-method-mollie-paysafecard {
    background-image: url('~@/assets/mollie/paysafecard.png');
  }

  .payment-method-invoice {
    background-image: url('~@/assets/mollie/invoice.png');
  }
}
