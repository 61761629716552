
















































































































































































































































































































































.order-list {
  .shipping-method {
    font-size: .6rem;
  }

  .progress-bar {
    &::after {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  table.order-table {
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #cccccc;
    }
    th,
    td {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: top;
      color: #000000;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }

  .reference-field {
      max-width: 200px;
    }

    thead {
      box-shadow: 0 5px 12px -5px #a3a1a3;
    }

    thead th {
      font-weight: lighter;
      position: sticky;
      top: 130px;
      z-index: 1;
      background-color: #ffffff;
      border-bottom: 2px solid #cccccc;
      margin-bottom: 2px;
      box-shadow: 0 2px 0 0 #cccccc;
    }

    .background-white {
      background-color: white;
    }
  }

  @media (min-width: 1200px) {
    table.order-table {
      td,
      th {
        padding: 0.9rem 1rem;
      }
    }
  }

  @media (min-width: 1000px) {
    table.order-table {
      width: 100%;

      td,
      th {
        padding: 0.4rem 0.3rem;
        height: auto;
      }

      td:last-child,
      th:last-child {
        padding-right: 0.5rem;
      }

      td.actions,
      th.actions {
        text-align: right;

        .btn + .btn {
          margin-top: 0;
          margin-left: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    table.order-table {
      td:first-child,
      th:first-child {
        padding: 0;
      }

      thead {
        display: none;
      }

      tr {
        display: block;
        padding: 1rem;
      }

      td,
      th {
        padding: 0;
      }

      td {
        display: block;
        height: auto;
      }

      td.actions,
      th.actions {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;

        .btn + .btn {
          margin-top: 0.5rem;
          margin-left: 0;
        }
      }
    }
  }

  .needs-confirmation {
    font-style: italic;
  }
}
