

























































































































































































































































































































































































































































































































































































































































































































































.cart-list {

  li.no-bullet {
    list-style: none;
    padding: 0;
  }

  .cart-empty-msg {
    font-size: 1rem;
  }

  .cart-upload-block .btn {
    white-space: normal;
  }

  .btn-container-center {
    align-content: center;
  }

  .cart-reference-widget {
    .input-row-full, .form-control {
      padding-top: 0;
    }
  }

  .card-sticky {
    position: sticky;
    top: 107px;
    z-index: 6;
  }

  .medium-price {
    font-size: 0.9rem;
  }

  .large-price {
      font-size: 1.5em;
  }

  .coupon-container {
    display: grid;
    grid-template-columns: 1fr 130px;
    justify-content: flex-start;

    .coupon-label {
      grid-column: 1/2;
    }
    .coupon-price {
      grid-column: 2/3;
    }
  }

  .coupon-add-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-container {
      width: 100%;
    }
  }

  .cart-list-header {
    display: grid;
    align-items: center;

    .cart-list-header-select-all {
      grid-row: 1;
      grid-column: 1;
      display: flex;
    }

    .cart-list-header-actions {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      justify-content: flex-end;

      .btn {
        min-width: auto;
      }
    }
  }

  .cart-list-totals {
    .cart-list-totals-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .upload-hint h3 {
    border: 2px dashed #777;
    border-radius: 0.5rem;
    display: inline-block;
    font-size: 1.8em;
    line-height: 100%;
    margin: auto;
    max-width: 400px;
    width: auto;
  }

  .upload-button-divider {
    font-size: 1.2rem;
  }

  .bulk-discount-title {
    grid-row: 1;
  }

  .bulk-discount-price {
    grid-row: 1;
  }

  @media (max-width: 992px) {
    .cart-upload-block .upload-hint h3 {
      font-size: 1.6em;

      .filetypes-msg {
        font-size: 0.8em;
      }
    }
  }

  .cart-list-col-settings {
    .to-top-spacer {
      height: 300px;
      background-color: #fff;
    }

    .to-top-btn {
      position: sticky;
      top: calc(100% - 60px);
      z-index: 0;
      float: right;
    }
  }
}

