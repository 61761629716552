





























































































































































































































































































































































































































































































































































































































.cart-review-view {
  .icp-details .custom-control-input:disabled ~ .custom-control-label {
    color: #000000;
  }

  .no-pointer {
    pointer-events: none;
  }

  .icp-conclusion {
    font-size: 0.875rem;
  }

  .icp-vies-details {
    padding-top: 0.5rem;
    padding-left: 2rem;
  }

  .vat-detail {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .card-body ul.bg-dark {
    margin-left: 0;
    padding: 1rem;
    list-style: none;
  }

  .deliver-summary-list {
    list-style-type: none;
  }

  .email-card {
    .email-buttons {
      display: flex;
      justify-content: space-between;
    }
    textarea {
      width: 100%;
      min-height: 500px;
    }
  }
}

