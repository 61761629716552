






















































































































.cart-list-item-quantity-widget {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 3;

  .qty-button {
    min-width: 0;
    font-size: 0.9rem;
  }

  .qty-input-group {
    display: inline-block;
    margin-bottom: -15px;
    border-color: green !important;
    border-width: 10px !important;

    input.form-control {
      box-shadow: none !important;
      min-width: 35px;
    }
  }
}
