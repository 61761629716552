





























































































































































.admin-bar {
  background-color: #ffe400;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: space-between;

  .user-warning {
    display: flex;
    font-size: 0.8rem;
    align-items: center;

    .warning-icon {
      font-size: 1.5rem;
      background-color: #fff;
      color: #f00;
      border-radius: 50%;
    }

    .client-name {
      margin-left: 0.4rem;
      font-weight: bold;
    }
  }

  .admin-multiselect {
    max-width: 600px;

    .multiselect__option.multiselect__option--group {
      color: rgb(109, 109, 109);
      font-weight: bold;;
      padding-left: 0;
      font-size: 0.6rem;
    }

    .multiselect__option {
      color: #000;
      border-bottom: 1px solid  rgb(221, 221, 221);
      padding-top: 0.3rem;
      font-size: 0.8rem;

      .option__action {
        display: none;
        font-style: italic;
        color:rgb(109, 109, 109);
      }

      .option__col div {
        display: inline-block;
      }

      .option__id {
        width: 50px;
        color: #555;
      }
    }

    .multiselect__option--highlight {
      background-color: #ffe400;

      .option__action {
        display: inline-block;
      }
    }

    .option__grouplabel {
      color: #000;
      font-size: 0.6rem;
    }

    .option__row {
      display: flex;
      grid-template-columns: 1fr 1fr;;
      justify-content: space-between;
    }
  }
}
